exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sunset-cocktail-happy-hour-hop-js": () => import("./../../../src/pages/sunset-cocktail-happy-hour-hop.js" /* webpackChunkName: "component---src-pages-sunset-cocktail-happy-hour-hop-js" */),
  "component---src-pages-tik-e-bikes-js": () => import("./../../../src/pages/tik-e-bikes.js" /* webpackChunkName: "component---src-pages-tik-e-bikes-js" */),
  "component---src-pages-tik-e-tours-js": () => import("./../../../src/pages/tik-e-tours.js" /* webpackChunkName: "component---src-pages-tik-e-tours-js" */),
  "component---src-pages-weddings-js": () => import("./../../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */)
}

